import React from 'react';

const TermsPage = () => {
    return (
        <div>
            Terms of use page
        </div>
    );
}

export default TermsPage;
