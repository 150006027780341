import React from 'react';
import { Link } from "react-router-dom";

import "./Footer.scss";

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer__inner-wrapper">
                <Link to="/">
                    <div className='left'>diplooo.com</div>
                </Link>
                <div className='right'>
                    <Link to="/terms-of-use"><span>Terms of use</span></Link>
                    <Link to="/cookies-policy"><span>Cookies</span></Link>
                    <Link to="/privacy-policy"><span>Privacy</span></Link>
                </div>
            </div>
        </div>
    );
}

export default Footer;
