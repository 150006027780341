// Card.jsx
import React from 'react';
import { Link } from "react-router-dom";
import "./Card.scss";

export default function Card({author, views, likes, templateID, label}) {
    let logo;
    if (author.name) {
        logo = (
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                <path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/>
            </svg>
        );
    } else {
        logo = (
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="20px" height="20px" viewBox="0 0 192.000000 192.000000"
                preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,192.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                    <path d="M376 1904 c-181 -44 -333 -199 -366 -374 -13 -70 -13 -1070 0 -1141
                    34 -182 200 -343 389 -379 32 -6 260 -10 565 -10 415 0 523 3 574 15 197 47
                    345 203 375 397 30 196 -78 400 -261 491 -40 20 -93 39 -117 43 -56 8 -58 19
                    -3 28 157 25 330 193 367 357 13 56 13 162 0 218 -39 172 -205 328 -383 360
                    -95 17 -1065 13 -1140 -5z m306 -123 c81 -40 149 -109 186 -189 l27 -57 0
                    -575 0 -575 -27 -57 c-37 -80 -105 -149 -186 -189 -63 -32 -73 -34 -172 -34
                    -99 0 -110 2 -172 33 -116 57 -195 157 -218 277 -8 42 -10 218 -8 580 3 512 3
                    521 25 575 43 105 136 193 245 232 41 14 74 18 143 15 78 -3 99 -7 157 -36z
                    m464 -744 c49 -30 147 -67 179 -67 14 0 25 -4 25 -10 0 -5 -11 -10 -23 -10
                    -67 0 -191 -63 -264 -133 l-43 -41 0 183 0 184 44 -41 c24 -22 61 -51 82 -65z"/>
                </g>
            </svg>
        );
    }
    return (
            <div className='card'>
                <Link to={'TemplatePage' + templateID}>
                    <div className="card__cover" style={{ backgroundImage: `url(${require(`../../assets/template${templateID}.jpg`)})` }}></div>
                </Link>
                <div className='card__footer'>
                    {/* <div className='logo'>{logo}</div> */}
                    <div>
                        <div className='template-label'>{label}</div>
                        {/* <div className='author-name'>Diplooo team</div> */}
                    </div>
                    {/* <div className='likes-icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                            <path d="m480-144-50-45q-100-89-165-152.5t-102.5-113Q125-504 110.5-545T96-629q0-89 61-150t150-61q49 0 95 21t78 59q32-38 78-59t95-21q89 0 150 61t61 150q0 43-14 83t-51.5 89q-37.5 49-103 113.5T528-187l-48 43Z"/>
                        </svg>
                    </div>
                    <div className='likes-label'>{likes}</div>
                    <div className='views-icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                            <path d="M480-312q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Zm0-72q-40 0-68-28t-28-68q0-40 28-68t68-28q40 0 68 28t28 68q0 40-28 68t-68 28Zm0 192q-142.596 0-259.798-78.5T48-480q55-131 172.202-209.5T480-768q142.596 0 259.798 78.5T912-480q-55 131-172.202 209.5T480-192Z"/>
                        </svg>
                    </div>
                    <div className='views-label'>{views}</div> */}
                </div>
            </div>
    )

}