import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";

import "./TemplatePage.scss";

import paperformats from "../../paperformats";
import diplomTemplates from "../../diplomTemplates";

import Sidebar from "../../components/Sidebar/Sidebar";

import html2pdf from "html2pdf.js";

export default function TemplatePage({templateID}) {

    const [diploma, setDiploma] = useState({
        format: "a4",
        orientation: "landscape",
        id: templateID,
        template: diplomTemplates.find((diplom) => diplom.id === templateID)
          .template,
        params: diplomTemplates.find((diplom) => diplom.id === templateID)
          .params,
    });

    const style = {
        width: paperformats[diploma.format][diploma.orientation].width,
        height: paperformats[diploma.format][diploma.orientation].height,
    };
    
    const tmplCanvasStyle = {
      aspectRatio: paperformats[diploma.format][diploma.orientation].aspectRatio,
      backgroundColor: 'red'
    }

    useEffect(() => {
        scaleLayout();
    }, [diploma]);

    const tmplAreaRef = React.useRef(null);
    const tmplWrapperRef = React.useRef(null);

    const scaleLayout = () => {
        // console.log('diploma or', diploma.orientation);
        // console.log('canvasRef oW', canvasRef.current.offsetWidth);
        // console.log('canvasRef oH', canvasRef.current.offsetHeight);
        // console.log('diplomTemplateRef oH', diplomTemplateRef.current.offsetHeight);
        // console.log('diplomTemplateRef oW', diplomTemplateRef.current.offsetWidth);
        // console.log('diplomTemplateRef oWa', diplomTemplateRef.current);
        console.log('a', tmplWrapperRef.current.offsetWidth - tmplAreaRef.current.offsetWidth);
        const diplomaOrientation = diploma.orientation;
        if(diplomaOrientation === 'landscape') {
          tmplWrapperRef.current.style.zoom = 
            (tmplAreaRef.current.offsetWidth * 1) /
            Number(
              paperformats[diploma.format][diploma.orientation].width.replace(
                /px$/,
                ""
              )
            ).toFixed(2);
        }
        if(diplomaOrientation === 'portrait') {
          tmplWrapperRef.current.style.zoom = 
            (tmplAreaRef.current.offsetHeight * 1) /
            Number(
              paperformats[diploma.format][diploma.orientation].height.replace(
                /px$/,
                ""
              )
            ).toFixed(2);
        }
    };

    const handlePrint = () => {
      const element = tmplWrapperRef.current;
      var clonedElement = element.cloneNode(true);
      clonedElement.style.zoom = 1;
      const opt = {
        filename: ".pdf",
        margin: [-1, -1],
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          scale: 2,
        },
        jsPDF: {
          unit: "pt",
          format: diploma.format,
          orientation: diploma.orientation,
          hotfixes: ["px_scaling"],
        },
      };
      html2pdf().from(clonedElement).set(opt).save();
      clonedElement.remove();
    };

    const handleFormat = (event) => {
      setDiploma((currValue) => ({
        ...currValue,
        format: event.target.value,
      }));
    };
  
    const handleOrientation = (event) => {
      setDiploma((currValue) => ({
        ...currValue,
        orientation: event.target.value,
      }));
      // scaleLayout(event.target.value);
    };
  
    const handleParams = (event) => {
      const newParams = {
        ...diploma.params,
        [event.target.name]: [event.target.value],
      };
      setDiploma((currValue) => ({
        ...currValue,
        params: newParams,
      }));
    };

    const handleReset = (e) => {
      setDiploma((currValue) => ({
        ...currValue,
        format: "a4",
        orientation: "landscape",
        params: diplomTemplates.find(
          (diplom) => diplom.id === parseInt(diploma.id)
        ).params,
      }));
    };

    return (
        <div className='container'>
            <div className="info-header">
              <div>Abstract modern Certificate</div>
            </div>
            <div className='main'>
                <div ref={tmplAreaRef} className='template-area'>
                    <div ref={tmplWrapperRef} className="template-wrapper" style={style}>
                      <diploma.template {...diploma.params}/>
                    </div>
                    <div className="template-info">
                      <p>About this template</p>
                      <p>Envisioned to encapsulate the essence of achievement, our diploma boasts a minimalist design that harmoniously balances simplicity and elegance. Its clean layout and uncluttered aesthetic create a captivating canvas that ensures the focal point remains your accomplishment. Featuring a meticulously selected font that exudes timeless sophistication, the text effortlessly guides the eye, allowing for easy absorption of pertinent details</p>
                    </div>
                </div>
                <div className="sidebar">
                  <Sidebar
                    {...diploma.params}
                    handleFormat={handleFormat}
                    handleOrientation={handleOrientation}
                    handleParams={handleParams}
                    handleReset={handleReset}
                    handlePrint={handlePrint}
                    format={diploma.format}
                    orientation={diploma.orientation}
                  />
                </div>
            </div>
        </div>
    )
}