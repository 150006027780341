// Home.js
import React from 'react';
import Card from '../../components/Cards/Card'
import diplomTemplates from "../../diplomTemplates";

import "./Home.scss";

export default function Home() {
  // const sortedDiplomTemplates = diplomTemplates.sort(() => Math.random() - 0.5);
  const sortedDiplomTemplates = diplomTemplates;
  console.log (sortedDiplomTemplates);
  return (
    <div className='home'>
      <div className="hero">
        <div className="hero__inner-wrapper">
          <div className='text-group'>
            <p>Beatiful and simple diplom templates</p>
            <p><span>Easy</span> to configure and export to <span>PDF</span></p>
          </div>
        </div>
      </div>
      <div className="catalogue">
          <div className="catalogue__heading">Diplom templates</div>
          <div className="catalogue__grid">
            {sortedDiplomTemplates.map(function(template) { 
              return (
                <Card templateID={template.id} author={template.author} likes={template.likes} views={template.views} label={template.label}></Card>
              );
            })}
          </div>
        </div>
    </div>
  );
}
