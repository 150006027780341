import { useState, React } from "react";
import './App.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Home from './pages/Home/Home';
import diplomTemplates from "./diplomTemplates";
import TemplatePage from "./pages/TemplatePage/TemplatePage";
import Footer from "./components/Footer/Footer";
import Modal from "./components/Modal/Modal";
import TermsPage from "./pages/Terms/TermsPage";
import CookiesPage from "./pages/Cookies/CookiesPage";
import PrivacyPage from "./pages/Privacy/PrivacyPage";
export default function App() {
  const [showModal, setShowModal] = useState(false);
  return (
    <Router>
      <div className="diplooo-app">
        <div className="diplooo-app__navbar">
          <div className="wrapper">
          <Link to="/">
            <div className="imagotype">
              <div className="logo"></div>
              <div className="title">
                <span>diplooo</span>
                <span className="label-version">VERSION 1.0.0</span>
              </div>
            </div>
          </Link>
            <div className="actions">
              <button className="btn-text" onClick={() => setShowModal(true)}>
                <span>About this site</span>
                <span class="material-icons-outlined">help_outline</span>
              </button>
            </div>
          </div>
        </div>
        <div className="diplooo-app__main">
          <div className="diplooo-app__content">
            <Routes>
              <Route path="/" element={ <Home/> } />
              <Route path="/terms-of-use" element={ <TermsPage/> } />
              <Route path="/cookies-policy" element={ <CookiesPage/> } />
              <Route path="/privacy-policy" element={ <PrivacyPage/> } />
              {diplomTemplates.map(function(template) { 
                return (
                  <Route path={`/templatePage${template.id}`} element={ <TemplatePage templateID={template.id} /> } />
                );
              })}
            </Routes>
          </div>
          <Footer></Footer>
        </div>
        { showModal ? <Modal className="" toggleModal={setShowModal} /> : null }
      </div>
    </Router>
  );
}