import Template01 from "./components/Templates/Template-01/Template01";
import Template02 from "./components/Templates/Template-02/Template02";
import Template03 from "./components/Templates/Template-03/Template03";
import Template04 from "./components/Templates/Template-04/Template04";
import Template05 from "./components/Templates/Template-05/Template05";
import Template06 from "./components/Templates/Template-06/Template06";
import Template07 from "./components/Templates/Template-07/Template07";
import Template08 from "./components/Templates/Template-08/Template08";
import Template09 from "./components/Templates/Template-09/Template09";
import Template10 from "./components/Templates/Template-10/Template10";
import Template11 from "./components/Templates/Template-11/Template11";
import Template12 from "./components/Templates/Template-12/Template12";
import Template13 from "./components/Templates/Template-13/Template13";
import Template14 from "./components/Templates/Template-14/Template14";
import Template15 from "./components/Templates/Template-15/Template15";
const diplomTemplates = [
  {
    id: "03",
    template: Template03,
    params: {
      fname: "Kyla Streich",
      cname: "UX/UI Design Mastery",
      cdescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ac placerat quam. Duis auctor eget ante in pharetra. ",
      cdate: "19/07/1980",
      },
    author: {
      name: "",
      link: ""
    },
    likes:89,
    views:673,
    label: "Abstract modern Certificate"
  },
  {
    id: "05",
    template: Template05,
    params: {
      fname: "Ena Huel",
      cname: "Coding Fundamentals for Non-Tech Professionals",
      cdescription:
        "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati",
        cdate: "19/07/1980",
      },
    author: {
      name: "",
      link: ""
    },
    likes:77,
    views:529,
    label: "Abstract modern Certificate"
  },
  {
    id: "09",
    template: Template09,
    params: {
      fname: "Antwan Mante DDS",
      cname: "Social Media Marketing Strategies",
      cdescription:
        "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati",
        cdate: "19/07/1980",
      },
    author: {
      name: "",
      link: ""
    },
    likes:77,
    views:529,
    label: "Abstract modern Certificate"
  },
  {
    id: "10",
    template: Template10,
    params: {
      fname: "Stefan Graham",
      cname: "Responsive Web Design",
      cdescription:
        "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati",
        cdate: "December 2023",
      },
    author: {
      name: "",
      link: ""
    },
    likes:77,
    views:529,
    label: "Abstract modern Certificate"
  },
  {
    id: "11",
    template: Template11,
    params: {
      fname: "Murray Lesch",
      cname: "AI and Machine Learning Applications in Marketing",
      cdate: "12/27/2023",
    },
    author: {
      name: "",
      link: ""
    },
    likes:77,
    views:529,
    label: "Abstract modern Certificate"
  },
  {
    id: "12",
    template: Template12,
    params: {
      fname: "Lisa Thompson",
      cname: "Visual Communication in the Digital Era: Design Principles and Practices",
      cdate: "December 2020",
    },
    author: {
      name: "",
      link: ""
    },
    likes:77,
    views:529,
    label: "Abstract modern Certificate"
  },
  {
    id: "13",
    template: Template13,
    params: {
      fname: "Shakira Pouros",
      cname: "E-Commerce Optimization: Strategies for Sales and Conversion",
      cdescription:
        "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati",
      cdate: "December 2020",
    },
    author: {
      name: "",
      link: ""
    },
    likes:77,
    views:529,
    label: "Abstract modern Certificate"
  },
  {
    id: "14",
    template: Template14,
    params: {
      fname: "Adriel Bahringer V",
      cname: "Technology Integration in Marketing: Enhancing Campaign Performance",
      cdate: "December 2020",
    },
    author: {
      name: "",
      link: ""
    },
    likes:77,
    views:529,
    label: "Abstract modern Certificate"
  },
  {
    id: "15",
    template: Template15,
    params: {
      fname: "Jeramie Kovacek",
      cname: "Integrated Digital Innovation",
      cdate: "07 / 19 / 2016",
      cdescription:
        "Unlock the synergy of technology, marketing, and design in our 'Integrated Digital Innovation' course. Gain expertise in harmonizing these pillars for impactful business growth. Learn to strategize, create, and implement cutting-edge solutions in today's dynamic digital landscape",
      
    },
    author: {
      name: "",
      link: ""
    },
    likes:77,
    views:529,
    label: "Abstract modern Certificate"
  }
];

export default diplomTemplates;
