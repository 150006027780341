const paperformats = {
  a4: {
    //   width: "210mm",
    //   height: "297mm",
    landscape: {
      width: "1122.52px",
      height: "793.70px",
      // width: "853px",
      // height: "603.2px",
      aspectRatio: 1.414287514
    },
    portrait: {
      width: "793.70px",
      height: "1122.52px",
      aspectRatio: 0.707069807
    },
  },
  letter: {
    //   width: "216mm",
    //   height: "279mm",
    landscape: {
      width: "1054.49px",
      height: "816.38px",
      aspectRatio: 1.291665646
    },
    portrait: {
      width: "816.38px",
      height: "1054.49px",
      aspectRatio: 0.77419416
    },
  },
  legal: {
    //   width: "216mm",
    //   height: "356mm",
    landscape: {
      width: "1345.51px",
      height: "816.38px",
      aspectRatio: 1.648141797
    },
    portrait: {
      width: "816.38px",
      height: "1345.51px",
      aspectRatio: 0.606743911
    },
  },
  tabloid: {
    //   width: "279mm",
    //   height: "432mm",
    landscape: {
      width: "1632.75px",
      height: "1054.4px",
      aspectRatio: 1.548511002
    },
    portrait: {
      width: "1054.4px",
      height: "1632.75px",
      aspectRatio: 0.645781656
    },
  },
};
export default paperformats;
