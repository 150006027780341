import React from "react";
import "./Modal.scss";
 
const Modal = ({ toggleModal }) => {
    return (
        <div>
            <div className="modal">
                <div className="modal-content">
                    <button className="btn-basic btn-close" onClick={() => toggleModal(false)}>
                        <span className="material-icons-outlined">close</span>
                    </button>
                    <header>
                        <h2>About diplooo</h2>
                    </header>
                    <body>
                        <div>
                            <p>Diplooo is web-based app that let you choose from a wide range of templates that cater to all educational levels and fields in minutes! </p>
                            <p>Whether you're a teacher or a school administrator, diplooo's user-friendly interface helps you to generate crisp and professional-looking diplomas that are tailored to your requirements.</p>
                            <p>If you like Diplooo, follow us on <a href="https://twitter.com/diplooodotcom">Twitter</a> to stay in the loop with the latest features and generators.</p>
                        </div>
                    </body>
                </div>
            </div>
        </div>
    );
}
 
export default Modal;

{/* <h1>Customize Beautiful Diplom Templates Online!</h1>
<p className="mb-3">Are you in search of a personalized touch to your diplomas? Look no further than Diplooo - your perfect solution to customize and print simple and beautiful diploma templates.</p>
<h2>Customize Your Diplomas in Minutes</h2>
<p className="mb-3">With Diplooo, you can easily create and customize your diplomas online in minutes! Whether you're a teacher or a school administrator, Diplooo's user-friendly interface helps you to generate crisp and professional-looking diplomas that are tailored to your requirements.</p>
<h2>Choose from a Wide Range of Templates</h2>
<p className="mb-3">We offer an extensive selection of customizable diploma templates that cater to all educational levels and fields. Be it high schools, colleges, or universities, you can find everything you need in one place. Our templates come equipped with stunning graphics and fonts to make your diplomas stand out.</p>
<h2>Print and Share Your Diplomas with Ease</h2>
<p className="mb-3">Once you're done customizing your diploma templates, you can download your finished product in PDF format and print it on high-quality paper. Your diplomas will be ready for presentation at graduation ceremonies, award ceremonies, and beyond. You can also share them digitally via email, social media, and other online platforms - it's that simple!</p>
<p className="mb-3">Don't wait any longer to create your perfect diplomas! Visit Diplooo and start customizing today.</p> */}

