import React from 'react';

const PrivacyPage = () => {
    return (
        <div>
            Privacy page
        </div>
    );
}

export default PrivacyPage;
