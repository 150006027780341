import React, { useState } from "react";
import "./Sidebar.scss";

const templateDataLabels = Object.freeze({
  fname: 'Student',
  cname: 'Course',
  cdescription: 'Description',
  cdate: 'Date',
})

export default function Sidebar({
  format,
  orientation,
  handleFormat,
  handleOrientation,
  handleParams,
  handleReset,
  handlePrint,
  ...rest
}) {
  const templateParams = { ...rest };
  const handleFocus = (e) => {
    e.target.select();
    console.log('e.target', e);
  };
  return (
    <div className="sidebar__wrapper">
      {/* <div className="sidebar__header">
        <span>Configure your diplom</span>
        <button className="btn-text">
          <span className="material-icons-outlined">help</span>
        </button>
      </div> */}
      <div className="sidebar__main">
        <form spellCheck="false">
          <div className="form-group">
            <div className="title">output</div>
            <p className="subtitle">Formats</p>
            <div className="radio-group">
              <label className="rb-container" htmlFor="a4">A4
                <input
                  type="radio"
                  value="a4"
                  id="a4"
                  checked={format === "a4"}
                  onChange={handleFormat}
                  name="format"
                />
                <span className="checkmark"></span>
              </label>
              <label className="rb-container" htmlFor="letter">Letter
                <input
                  type="radio"
                  value="letter"
                  id="letter"
                  checked={format === "letter"}
                  onChange={handleFormat}
                  name="format"
                />
                <span className="checkmark"></span>
              </label>
              <label className="rb-container" htmlFor="legal">Legal
                <input
                    type="radio"
                    value="legal"
                    id="legal"
                    checked={format === "legal"}
                    onChange={handleFormat}
                    name="format"
                  />
                  <span className="checkmark"></span>
              </label>
            </div>
            <p className="subtitle">Orientation</p>
            <div className="radio-group">
              <label className="rb-container" htmlFor="landscape">Horizontal
                <input
                  type="radio"
                  value="landscape"
                  id="landscape"
                  checked={orientation === "landscape"}
                  onChange={handleOrientation}
                  name="orientation"
                />
                <span className="checkmark"></span>
              </label>
              <label className="rb-container" htmlFor="portrait">Vertical
                <input
                  type="radio"
                  value="portrait"
                  id="portrait"
                  checked={orientation === "portrait"}
                  onChange={handleOrientation}
                  name="orientation"
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <div className="form-group form-group-data">
            <div className="title">data</div>
            {Object.entries(templateParams).map(function (entries) {
              const textInput = (
                <div>
                  <p className="subtitle" style={{marginTop:"1.6rem"}}>{templateDataLabels[entries[0]]}</p>
                  <input
                    key={entries[0]}
                    type={entries[0] === "cdescription" ? "textarea" : "text"}
                    id={entries[0]}
                    name={entries[0]}
                    value={entries[1]}
                    onChange={handleParams}
                    onFocus={handleFocus}
                  />
                </div>
              );
              const textareaInput = (
                <div>
                  <p className="subtitle" style={{marginTop:"1.6rem"}}>{templateDataLabels[entries[0]]}</p>
                  <textarea
                    rows="10"
                    cols="50"
                    key={entries[0]}
                    id={entries[0]}
                    name={entries[0]}
                    value={entries[1]}
                    onChange={handleParams}
                    onFocus={handleFocus}
                  ></textarea>
                </div>
              );
              return entries[0] === "cdescription" ? textareaInput : textInput;
            })}
          </div>
        </form>
      </div>
      <div className="sidebar__footer">
        <button
          className="btn-solid"
          onClick={handlePrint}
        >
          <span>Generate PDF</span>
        </button>
        <button
            className="btn-basic"
            onClick={handleReset}
          >
            <span>Reset</span>
        </button>
      </div>
    </div>
  );
}
