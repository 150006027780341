import "./Template03.scss";

export default function Template03({ fname, cname, cdescription, cdate }) {
  return (
    <div className="template-03 fx-row-center txt-400">
      <div className="wrapper fx-row-center">
        <div className="inner-wrapper">
          <div className="group margin-bottom-4">
            <div className="txt-32 txt-uppercase txt-500 primary">Certificate Graduation</div>
            <div className="badge">
              <svg xmlns="http://www.w3.org/2000/svg" height="80" viewBox="0 -960 960 960" width="80">
                  <path fill="#FFF" d="m340.348-41.912-79.392-135.653-157.783-32.695 18.131-153.227L21.347-480l99.957-115.757-18.131-153.548 158.059-32.13 78.909-136.653 140.038 64.367 140.039-64.367 79.785 136.653 156.824 32.13-18.131 153.548L938.653-480l-99.957 116.513 18.131 153.227-156.783 32.695-79.826 135.653L480-106.173l-139.652 64.26Zm34.764-103.306 104.807-44.753 107.82 44.753 65.87-98.304 114.739-29.435-11.435-117.412 78.739-89.679-78.739-91.691 11.435-117.304-114.739-27.435-67.677-98.304-105.829 44.694-107.842-44.694-65.71 98.44-114.333 27.045 10.869 117.558L124.348-480l78.739 89.739-10.869 119.565 114.229 27.044 68.665 98.434ZM480-480Zm-43 137.522L668.522-572l-50.087-45.522-181.435 180-94.435-99.565L291.478-487 437-342.478Z"/>
              </svg>
            </div>
          </div>
          <div className="txt-16 txt-color-999 margin-bottom-24">Awarded to</div>
          <div className="name txt-32 txt-700 margin-bottom-48">{fname}</div>
          <div className="txt-16 txt-color-999 margin-bottom-24">For graduating from</div>
          <div className="course txt-32 txt-600 margin-bottom-36">{cname}</div>
          <div className="description txt-16 txt-color-999 margin-bottom-48">{cdescription}</div>
          <div className="txt-16 txt-color-999 margin-bottom-24">Awarded on</div>
          <div className="date txt-16 txt-600">{cdate}</div>
        </div>
      </div>
    </div>
  );
}